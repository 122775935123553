/* Initialize methods */

$(document).ready(function() {
  $(window).bind("beforeunload", function () {
    if ("running" == FZ.getStatus()) {
      return "Timer is running.";
    }
    if ("saving" == FZ.getStatus()) {
      return "You're about to save a pomodoro.";
    }
  });
});
