import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "volume" ];

  play_ringing_tone() {
    let volume = this.volumeTarget.value;
    if (volume > 0) {
      let ringing_tone = new Audio("/sounds/ringing.mp3");
      ringing_tone.volume = volume / 100;
      ringing_tone.play();
    }
  }
}
