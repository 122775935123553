import { Controller } from "stimulus";

export default class extends Controller {

  initialize() {
    let calendar = this.application.getControllerForElementAndIdentifier(document.getElementById("calendar-body"), "calendar");
    if (calendar != null) {
      calendar.refresh();
    }
  }
}
