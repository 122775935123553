import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["contactCard", "contactName", "contactActivity", "contactNote"];

  updateScaleFactor() {
    let visible_contacts = document.querySelectorAll(".card-container:not([hidden])");
    let scales = [[4, 49], [12, 32], [16, 23], [25, 19], [36, 18]];
    let scale_factor = scales.find(function (scale) {
      return scale[0] >= this;
    }, visible_contacts.length);
    scale_factor = scale_factor[1] || 10;
    this.contactCardTargets.forEach(function (contact_card) {
      contact_card.style = "width: " + scale_factor + "%";
    });
    this.contactNameTargets.forEach(function (contact_name) {
      contact_name.style = "font-size: " + scale_factor * 10 + "%";
    });
    this.contactActivityTargets.forEach(function (contact_activity) {
      contact_activity.style = "font-size: " + scale_factor * 8 + "%";
    });
    this.contactNoteTargets.forEach(function (contact_note) {
      contact_note.style = "font-size: " + scale_factor * 7 + "%";
    });
  }
}
