// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import { Turbo, cable } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "chartkick/highcharts";

require("chartkick");
require("chart.js");
require("packs/notifier.js");
require("packs/FZ");
require("packs/FZ-init");

Rails.start();
window.Turbo = Turbo;
ActiveStorage.start();

import "controllers";
import dragula from "dragula";

let draggables = null;

function initDraggable() {
  if (draggables != null) {
    draggables.forEach(function (draggable) {
      draggable.destroy();
    });
  }
  draggables = [];
  let containers = [
    document.getElementById('dragula_todos'),
    document.getElementById('dragula_focus_tasks')
  ]
  containers.forEach(function (container) {
    let draggable = new FullCalendar.ThirdPartyDraggable(container, {
      itemSelector: '.task-card',
      mirrorSelector: '.gu-mirror', // the dragging element that dragula renders
      eventData: function (eventEl) {
        return {
          title: eventEl.dataset.title,
          duration: 25 * 60
        };
      }
    });
    draggables.push(draggable);
  });
}
window.initDraggable = initDraggable;

function moveTask(el, target, source, sibling) {
  switch(target.id) {
    case 'dragula_focus_tasks':
      document.getElementById("task_" + el.dataset.graphId + "_new_task").requestSubmit();
      break;
    case 'dragula_todos':
      document.getElementById("deleteTask_" + el.dataset.id).requestSubmit();
      break;
  }
}

let drake = null;

$( document ).on('turbo:load', function() {
  // Set default iconBase to fontAwesome
  $.fn.selectpicker.Constructor.DEFAULTS.iconBase='fa';

  if (drake != null) {
    drake.destroy();
  }
  drake = dragula({
    isContainer: function (el) {
      return el.classList.contains('dragula-container');
    },
    moves: function (el, container, handle) {
      return container.id == 'dragula_todos' || container.id == 'dragula_focus_tasks';
    },
    revertOnSpill: true
  });
  drake.on("drop", moveTask);
});
