/* Focustimer (c) Dstny */

window.FZ = (function () {
  let timerInterval = null,
    originalTitle,
    status = "idle",
    settings = {
      timerTickingSoundId: "tictac",
      timerEndSoundId: "ringing",
      timerResetSoundId: "restartButton",
      startButtonId: "startButton",
      resetButtonId: "resetButton",
      startHintId: "start_hint",
      progressBarId: "timer-progress",
      overlayId: "overlay",
      formId: "new_tomato_form",
      timerId: "timer",
      timerCounterId: "timer_counter",
      flashId: "flash",
    };

  let sound_ringing = new Audio("/sounds/ringing.mp3");

  let init = function (options) {
    $.extend(settings, options);
  };

  let mapElements = function () {
    let ids = Array.prototype.shift.call(arguments),
      f = Array.prototype.shift.call(arguments),
      args = arguments;

    ids.forEach(function (id) {
      $("#" + id)[f].apply($("#" + id), args);
    });
  };

  let show = function (ids) {
    mapElements(ids, "show");
  };

  let hide = function (ids) {
    mapElements(ids, "hide");
  };

  let disable = function (ids) {
    mapElements(ids, "prop", "disable", true);
  };

  let enable = function (ids) {
    mapElements(ids, "prop", "disable", false);
  };

  let blur = function (ids) {
    mapElements(ids, "css", "opacity", 0.3);
  };

  let unblur = function (ids) {
    mapElements(ids, "css", "opacity", 1);
  };

  let pad = function (number, length) {
    let str = "" + number;
    while (str.length < length) {
      str = "0" + str;
    }
    return str;
  };

  let secondsToString = function (seconds) {
    let minutes = Math.floor(seconds / 60);
    seconds = seconds - minutes * 60;
    return pad(minutes, 2) + ":" + pad(seconds, 2);
  };

  let stateStart = function () {
    log("stateStart");
    $(document).trigger("timer_start");

    status = "running";
    originalTitle = document.title;

    disable([settings.startButtonId]);
    blur([settings.startButtonId, settings.startHintId]);
    hide([settings.formId, settings.startButtonId]);
    show([settings.timerId, settings.overlayId, settings.resetButtonId]);
  };

  let stateCounting = function (timer, duration) {
    log("stateCounting");
    $(document).trigger("timer_tick", [duration - timer, duration]);

    let timerString = secondsToString(timer);
    $("#" + settings.timerCounterId).html(timerString);
    document.title = timerString + " - " + originalTitle;

    let factor = (duration - timer) / duration;
    log("factor: " + factor);

    $("#" + settings.progressBarId).css("width", factor * 100 + "%");
  };

  let stateStop = function (reset) {
    log("stateStop");
    $(document).trigger("timer_stop");

    status = "idle";
    document.title = "DONE";
    $("#" + settings.flashId).html("");
    resetProgressBar();
    $("#" + settings.timerCounterId).html(secondsToString(gon.focuszone_duration));
    setVolume(gon.ringing_enabled ? gon.ringing_volume : 0);

    if (typeof reset == "undefined") {
      if (
        !NOTIFIER.notify(
          '',
          Translations.app_name,
          Translations.pomodoro_finished, // Later fix to break is over
          false
        )
      ) {
        log(
          'Permission denied. Click "Request Permission" to give this domain access to send notifications to your desktop.'
        );
      }
    }

    hide([settings.timerId]);
    hide([settings.startButtonId]);
    hide([settings.resetButtonId]);
    let title_text = $("#task_title").val();
    if (title_text != "") {
      let title = document.getElementById("title");
      title.innerHTML = title_text;
    }
    let checkpoints_json = $("#checkpoints").val();
    if (checkpoints_json != "") {
      let checkpoints = JSON.parse(checkpoints_json);
      if (checkpoints.length > 0) {
        let checklist = document.getElementById("checklist");
        let checklist_heading = document.getElementById("checklist_heading");
        checklist_heading.style.display = "block";
        checkpoints.forEach(function (checkpoint) {
          let id = "checkpoint_" + checkpoint['id'];
          let div = document.createElement("div");
          div.classList.add("form-check");
          let label = document.createElement("label");
          label.classList.add("form-check-label");
          label.innerHTML = checkpoint['title'];
          let checkbox = document.createElement("input");
          checkbox.id = id;
          checkbox.name = id;
          checkbox.type = "checkbox";
          checkbox.classList.add("form-check-input");
          if (checkpoint['is_checked']) {
            checkbox.checked = true;
          }
          checklist.appendChild(div);
          div.appendChild(checkbox);
          div.appendChild(label);
        });
      }
    }
    $("#progress").val($("#task_progress").val());
    $("#progress_display").val($("#progress").val());
    $("#complete").prop("checked", false);
    $("#note").val("");
    $("#pomodoroModal").modal();
  };

  // currently now used, but might be later for showing form
  let stateNewForm = function () {
    log("stateNewForm");
    //$(document).trigger("new_tomato_form");

    status = "saving";
    document.title = originalTitle;

    // notify tomato end
    if (
      !NOTIFIER.notify(
        '',
        Translations.app_name,
        Translations.pomodoro_finished,
        false
      )
    ) {
      log(
        'Permission denied. Click "Request Permission" to give this domain access to send notifications to your desktop.'
      );
    }
    // Todo: setup form after and fokustime
    //hide([settings.timerId]);
    //show([settings.formId]);
    //$("#" + settings.formId + " input[type=text]").focus();
    resetProgressBar(true);
  };

  let start = function (secs, callback) {
    log("start timer for " + secs + " seconds");

    let duration = secs;
    let startDate = new Date();
    let pausedDate = new Date();
    let timer = duration;
    stateStart(timer);

    (function tick() {
      if ("pause_on" == getStatus()) {
        pausedDate = new Date();
        status = "paused";
      } else if ("pause_off" == getStatus()) {
        startDate = new Date() - (pausedDate - startDate) + 1000;
        status = "running";
      }
      if ("running" == getStatus()) {
        let msPassed = new Date() - startDate;
        timer = Math.round(duration - msPassed / 1000);

        stateCounting(timer, duration);
      }
      if (timer <= 0) {
        callback();
        if (getVolume() > 0) {
          sound_ringing.volume = getVolume() / 100;
          sound_ringing.play();
        }
      } else {
        timerInterval = setTimeout(tick, 1000);
      }
    })();
  };

  let reset = function (event) {
    log("reset fokustime");

    if (confirm(Translations.sure)) {
      clearInterval(timerInterval);
      stateStop(true);
      //soundManager.setVolume(settings.timerResetSoundId, volume).play();
      //sound_reset.play();
    }
    else {
      event.preventDefault();
    }
  };

  let log = function (object) {
    if (gon.DEBUG) {
      console.log(object);
    }
  };

  let getStatus = function () {
    return status;
  };

  let getVolume = function () {
    return volume;
  };

  let setVolume = function (newVolume) {
    volume = Math.max(Math.min(newVolume, 100), 0);
  };

  let resetProgressBar = function () {
    $("#" + settings.progressBarId).css("width", 0);
    hide([settings.overlayId]);
  };

  let Translations = {
    app_name: "Fokuszone",
    break_is_over: "Pausen er slut. Tid til at komme tilbage til arbejdet",
    pomodoro_finished: "Fokustid afsluttet",
    sure: "Sikker?",
    forget_to_start_new_tomato: "Har du glemt at starte din nye fokuszone?",
    forget_to_save_tomato: "Har du glemt at gemme din afsluttede fokuszone?",
  };

  return {
    start: start,
    reset: reset,
    stateNewForm: stateNewForm,
    stateStop: stateStop,
    log: log,
    getStatus: getStatus,
    getVolume: getVolume,
    setVolume: setVolume,
    resetProgressBar: resetProgressBar,
    secondsToString: secondsToString,
    settings: settings,
  };
})();
