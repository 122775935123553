import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["contactActivity", "contactNote"];
  static values = { subscriptionUrl: String, displayStatus: Array, activities: Object };

  connect() {
    const inner_value = function(xmlDocument, value) {
      let element = xmlDocument.getElementsByTagNameNS("*", value);
      return element.length > 0 && element[0].innerHTML
        ? element[0].innerHTML
        : "";
    };
    let activity_target = this.contactActivityTarget;
    let note_target = this.contactNoteTarget;
    let self_element = this.element;
    let display_status = this.displayStatusValue;
    let activities = this.activitiesValue;
    fetch(this.subscriptionUrlValue)
      .then(response => response.json())
      .then(function(response) {
        if (response) {
          let source = new EventSource(response['url']);
          source.onmessage = function (event) {
            let domParser = new DOMParser();
            let xmlDocument = domParser.parseFromString(event.data, "text/xml");
            let activity = inner_value(xmlDocument, "activity");
            activity_target.textContent = activities[activity]['name'];
            note_target.textContent = inner_value(xmlDocument, "note");
            self_element.hidden = !display_status.includes(activity);
            let update_event = new CustomEvent("update-scale-factor");
            window.dispatchEvent(update_event);
          };
        }
      })
  }
}
