import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    $("#" + FZ.settings.timerCounterId).html(FZ.secondsToString(gon.focuszone_duration));

    if (gon.active_pomodoro && 'idle' == FZ.getStatus()) {
      let endTime = new Date(gon.active_pomodoro.ended_at);
      let now = new Date().getTime();
      let distance = endTime - now;
      if (distance > 2) {
        FZ.start(distance/1000, FZ.stateStop);
        this.fullScreenProgress();
      }
    }
  }

  start() {
    if('idle' == FZ.getStatus()) {
      FZ.start(gon.focuszone_duration, FZ.stateStop);
      this.fullScreenProgress();
    }
  }

  reset(event) {
    if ("running" == FZ.getStatus()) {
      FZ.reset(event);
    }
  }

  fullScreenProgress() {
    $("#timer").appendTo("#main");
    document.getElementById("main").style.display = "flex";
    document.getElementById("timer_counter").style.fontSize = "8em";
    document.getElementById("timer_counter").style.color = "white";
  }

  editInfo(event) {
    console.log(event.params);
    $("#info_id").val(event.params.id);
    $("#info_progress").val(event.params.progress);
    $("#info_progress_display").val($("#info_progress").val());
    $("#info_note").val(event.params.note);
    $("#pomodoroInfoModal").modal();
  }
}
