import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["user_presence"];
  static values = { url: String };

  connect() {
    const inner_value = function(xmlDocument, value) {
      let element = xmlDocument.getElementsByTagNameNS("*", value);
      return element.length > 0 && element[0].innerHTML
        ? element[0].innerHTML
        : "";
    };
    if (this.urlValue) {
      let source = new EventSource(this.urlValue);
      source.onmessage = function (event) {
        let domParser = new DOMParser();
        let xmlDocument = domParser.parseFromString(event.data, "text/xml");
        //console.log(xmlDocument);
        if ($("#user_presence_select").length) {
          $("#user_presence_select").selectpicker("val", inner_value(xmlDocument, 'activity'));
          $("#user_note")[0].value = inner_value(xmlDocument, "note");
          $("#user_role")[0].value = inner_value(xmlDocument, "role");
        }
      };
    }
  }

  setPresence(event) {
    if (event.type == "keydown" && event.code != "Enter") {
      return;
    }

    let params = {activity: $("#user_presence_select").val(), note: $("#user_note").val(), role: $("#user_role").val()};
    Rails.ajax({
      type: "put",
      url: "/presence",
      data: new URLSearchParams(params).toString()
    });

    event.preventDefault();
  }
}
