import { Controller } from "stimulus";

export default class extends Controller {
  editInfo(event) {
    console.log(event.params);
    $("#todo_info_id").val(event.params.id);
    $("#todo_info_type").val(event.params.type);
    $("#todo_info_title").val(event.params.title);
    $("#todo_info_due_date").val(event.params.dueDate);
    $("#todoInfoModal").modal();
  }
}
