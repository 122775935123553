import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "calendar" ];
  static values = { language: String };
  fullcalendar = null;
  init_time = null;
  timer_id = null;

  initialize() {
    let calendarEl = this.calendarTarget;
    if (this.fullcalendar != null) {
      this.fullcalendar.destroy();
    }
    this.fullcalendar = new FullCalendar.Calendar(calendarEl, {
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next'
      },
      initialView: 'timeGridDay',
      timeZone: 'local',
      locale: this.languageValue,
      events: '/events.json',
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
        startTime: '8:00', // a start time (10am in this example)
        endTime: '17:00', // an end time (6pm in this example)
      },
      height: '100%',
      droppable: true,
      drop: function(info) {
        let params = {title: info.draggedEl.dataset.title, date: info.date};
        Rails.ajax({
          type: "post",
          url: "/events",
          data: new URLSearchParams(params).toString(),
          success: function(response) {
            location.reload();
          },
          error: function(response) {
            window.alert("Failed creating calendar event:\n" + response);
            location.reload();
          }
        });
      }
    });
    this.fullcalendar.render();
    this.init_time = new Date(Date.now());
    if (this.timer_id != null) {
      clearTimeout(this.timer_id);
    }
    this.timer_id = setInterval(this.checkDate.bind(this, this), 5000);
  }

  refresh() {
    this.fullcalendar.refetchEvents();
  }

  checkDate() {
    let time_now = new Date(Date.now());
    if (time_now.getDate() != this.init_time.getDate()) {
      this.initialize();
    }
  }
}
